import _path from "path";
import _isGlob from "is-glob";
var exports = {};
var path = _path;
var isglob = _isGlob;

exports = function globParent(str) {
  str += "a"; // preserves full path in case of trailing path separator

  do {
    str = path.dirname(str);
  } while (isglob(str));

  return str;
};

export default exports;